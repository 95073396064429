import {Controller} from '@hotwired/stimulus'
import Turbo from '~js/turbo'

export default class extends Controller {
  static targets = ['fileButton']
  static values = { duplicate: Object } // { source: target }

  debouncedSubmit() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.submit()
    }, 300)
  }

  fileInput(event) {
    const input = this.fileButtonTarget.parentElement.querySelector('input[type="file"]')

    if (input.files.length == 0) {
      event.preventDefault()
      input.click()
    } else {
      this.fileButtonTarget.click()
    }
  }

  submit() {
    if (this.hasDuplicateValue) {
      for (const [source, target] of Object.entries(this.duplicateValue)) {
        const sourceElement = this.element.querySelector(source)
        const targetElement = this.element.querySelector(target)
        targetElement.value = sourceElement.textContent
      }
    }

    this.element.requestSubmit()
  }

  next(event) {
    if (event.detail.success) {
      const fetchResponse = event.detail.fetchResponse

      if(fetchResponse.response.url && !fetchResponse.response.headers.get('X-SKIP-NEXT-FORM-URL')) {
        history.pushState(
          { turbo_frame_history: true },
          '',
          fetchResponse.response.url
        )

        Turbo.visit(fetchResponse.response.url)
      }
    }
  }

  resetFormPartAndSendUpdate(event) {
    const section = this.element.querySelector(event.params.removeSectionClass)
    if(section) section.remove()
    this.element.insertAdjacentHTML('afterbegin','<input type="hidden" name="onlyUpdateView" value="1">')
    this.submit()
  }
}
