import { Application } from '@hotwired/stimulus'

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

// ActionCable connections are broken when Pace tracks websockets
window.paceOptions = {
  ajax: {
    trackWebSockets: false
  }
}

export { application }
